//
// user.js
// Use this to write your custom JS
//

import './chart';

import {
  ArcElement,
  BarController,
  BarElement,
  BubbleController,
  CategoryScale,
  Chart,
  Decimation,
  DoughnutController,
  Filler,
  Legend,
  LineController,
  LineElement,
  LinearScale,
  LogarithmicScale,
  PieController,
  PointElement,
  PolarAreaController,
  RadarController,
  RadialLinearScale,
  ScatterController,
  TimeScale,
  TimeSeriesScale,
  Title,
  Tooltip,
} from 'chart.js';

Chart.register(
  ArcElement,
  BarController,
  BarElement,
  BubbleController,
  CategoryScale,
  Decimation,
  DoughnutController,
  Filler,
  Legend,
  LinearScale,
  LineController,
  LineElement,
  LogarithmicScale,
  PieController,
  PointElement,
  PolarAreaController,
  RadarController,
  RadialLinearScale,
  ScatterController,
  TimeScale,
  TimeSeriesScale,
  Title,
  Tooltip
);

// Feed chart

const feedChart = document.getElementById('monthlyLiveEventsChart');

if (feedChart) {
  let labels = JSON.parse(document.getElementById('monthlyChartLabels').textContent);
  let counts = JSON.parse(document.getElementById('monthlyChartCounts').textContent);
  new Chart(feedChart, {
    type: 'bar',
    options: {
      scales: {
        y: {
          ticks: {
            callback: function (value) {
              return value;
            },
          },
        },
      },
    },
    data: {
      labels: labels,
      datasets: [
        {
          label: 'Attendees',
          data: counts,
        }
      ],
    },
  });
}
